.marketboard {
  margin-top: 12px;
  position: relative;
  min-height: 150px;
}
.markerboard-markets {
  margin-bottom: 5px;
  height: 100%;
  background-color: #121212;
  overflow: hidden;
}
.marketboard-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 1;
}
.player-page .lazy-render {
  display: block;
  position: relative;
  box-sizing: border-box;
}
.market {
  background-color: #121212;
}
.market::before {
  content: "";
  display: block;
  margin-left: 10px;
  margin-right: 10px;
  height: 1px;
  background-color: rgba(36, 36, 36, 0.6);
}
sb-lazy-render:first-child .market:first-child::before {
  display: none;
}
.market--parlay-card .market__button {
  height: 44px;
  font-size: 11px;
  line-height: 12px;
}
.market--parlay-card .market__template {
  grid-gap: 2px;
}
.market--parlay-card .market__template--4-columns {
      grid-template-areas: ". . . .";
  -ms-grid-columns: 5fr (2fr)[3];
  grid-template-columns: 5fr repeat(3, 2fr);
}
.market--parlay-card .market__label--row {
  height: 44px;
}
.market__button {
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  gap: 0 5px;
  flex-wrap: wrap;
  margin: 0;
  padding: 0 2px;
  height: 48px;
  align-items: center;
  overflow: hidden;
  border: none;
  background-color: #242424;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  word-break: break-word;
  z-index: 0;
}
.market__button:disabled {
  pointer-events: none;
  opacity: 1;
}
.market__button:disabled .market__button-title {
  color: rgba(255, 255, 255, 0.6);
}
.market__button:disabled .market__button-points {
  color: rgba(255, 255, 255, 0.6);
}
.market__button:disabled .market__button-odds {
  color: rgba(255, 255, 255, 0.6);
}
.market__button.odds-update-indicator {
  pointer-events: none;
  animation: flash-outcome-cell-background 750ms linear;
}
.market__button:not(.market__button--empty) {
  cursor: pointer;
}
.market__button--grid-column-1 {
  -ms-grid-column: 1;
  grid-column: 1;
}
.market__button--grid-column-2 {
  -ms-grid-column: 2;
  grid-column: 2;
}
.market__button--grid-column-3 {
  -ms-grid-column: 3;
  grid-column: 3;
}
.market__button--with-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.market__button--selected {
  background-color: #fff;
}
.market__button--selected .market__button-title {
  color: #000;
}
.market__button--selected .market__button-points {
  color: #000;
}
.market__button--selected .market__button-odds {
  color: #000;
}
.market__button--selected:disabled .market__button-title {
  color: rgba(0, 0, 0, 0.6);
}
.market__button--selected:disabled .market__button-points {
  color: rgba(0, 0, 0, 0.6);
}
.market__button--selected:disabled .market__button-odds {
  color: rgba(0, 0, 0, 0.6);
}
.market__button-title {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.market__button-points {
  position: relative;
}
.market__button-odds {
  position: relative;
  display: block;
  margin-left: 11px;
  font-weight: bold;
  color: #53D337;
  flex-shrink: 0;
}
.market__button--loading {
  background-color: #fff;
}
.market__button--loading .market__button-odds,
.market__button--loading .market__button-title,
.market__button--loading .market__button-points {
  color: #000;
}
.market__button__animate-loading {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  background-color: #DADADA;
  animation: market__button__animate-loading 1.6s ease-in infinite;
}
.market-button-expandable__content .market__button {
  margin: 2px 0;
}
@keyframes market__button__animate-loading {
  0% {
    transform: translate(-100%);
  }
  100% {
    transform: translate(100%);
  }
}
@keyframes flash-outcome-cell-background {
  0% {
    background-color: #242424;
  }
  50% {
    background-color: #ababab;
  }
  100% {
    background-color: #242424;
  }
}
.market-button-expandable__primary {
  display: flex;
}
.market-button-expandable__primary .market__button--regular {
  flex-grow: 1;
}
.market-button-expandable__secondary .market__button--regular {
  width: calc(100% - 48px);
}
.market-button-expandable__secondary .market__button--regular:not(:last-child) {
  margin: 2px 0;
}
.market-button-expandable-arrow-wrapper {
  position: relative;
  padding: 0;
  width: 48px;
  height: 48px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}
.market-button-expandable-arrow {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 1;
}
.market-button-expandable-arrow-inner {
  width: 13px;
  height: 13px;
  transition: transform 0.2s ease-in-out;
}
.market-button-expandable-arrow-inner:before,
.market-button-expandable-arrow-inner:after {
  position: absolute;
  content: '';
  background-color: #C5C5C5;
  top: 5.5px;
  height: 2px;
  width: 9.10399981px;
}
.market-button-expandable-arrow-inner:before {
  left: -0.62614312px;
  transform: rotate(-45deg);
}
.market-button-expandable-arrow-inner:after {
  left: 4.45964331px;
  transform: rotate(45deg);
}
.market-button-expandable-arrow-inner--down {
  transform: rotate(180deg);
}
.market-button-expandable__content {
  overflow: hidden;
}
.markets-category {
  background-color: #121212;
  border-radius: 12px;
  margin-bottom: 12px;
  padding-right: 16px;
  padding-left: 16px;
}
.markets-category__name {
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 2px solid #242424;
  font-family: 'Saira Condensed';
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
}
.market-collapsible__trigger {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: 0;
  text-align: unset;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #121212;
  z-index: unset;
}
.market-collapsible__trigger:hover {
  cursor: pointer;
}
.market-collapsible__trigger:hover .market-collapsible-arrow {
  display: block;
}
.market-collapsible__content {
  position: relative;
  overflow: hidden;
}
.market-collapsible:not([data-collapsed="true"]:last-child) .market-collapsible__trigger {
  border-bottom: 1px solid #242424;
}
.player-page .market-collapsible-arrow {
  position: absolute;
  display: none;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.player-page .market-collapsible-arrow-inner {
  width: 13px;
  height: 13px;
  transition: transform 0.2s ease-in-out;
}
.player-page .market-collapsible-arrow-inner:before,
.player-page .market-collapsible-arrow-inner:after {
  position: absolute;
  content: '';
  background-color: #C5C5C5;
  top: 5.5px;
  height: 2px;
  width: 9.10399981px;
}
.player-page .market-collapsible-arrow-inner:before {
  left: -0.62614312px;
  transform: rotate(-45deg);
}
.player-page .market-collapsible-arrow-inner:after {
  left: 4.45964331px;
  transform: rotate(45deg);
}
.player-page .market-collapsible-arrow-inner--down {
  transform: rotate(180deg);
}
.market__header {
  box-sizing: border-box;
  margin: 0;
  display: flex;
  align-items: center;
  padding-right: 31px;
  padding-left: 16px;
  width: 100%;
  min-height: 50px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}
h2.market__header {
  font-family: 'Saira Condensed', sans-serif;
}
.market__label {
  box-sizing: border-box;
  display: block;
  margin: 0;
  padding: 6px;
  min-height: 24px;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  background-color: #121212;
  color: #C5C5C5;
  font-size: 10px;
  line-height: 16px;
  word-break: break-word;
}
.market__label--row {
  display: flex;
  gap: 0 16px;
  padding: 0 6px 0 12px;
  height: 48px;
  font-size: 12px;
  color: #fff;
}
@media (max-width: 480px) {
  .market__label--row {
    display: flex;
    gap: 0 8px;
  }
}
.market__label--text-left {
  text-align: left;
}
.market__label--truncate-strings {
  flex: 1;
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
  min-width: 20px;
}
.market__label--text-center {
  padding-right: 2px;
  padding-left: 2px;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
}
.market__label-name {
  box-sizing: border-box;
  padding: 0px 16px 12px 16px;
  -ms-grid-column: 1;
      grid-column-start: 1;
  -ms-grid-column-span: 2;
  grid-column-end: 3;
  background-color: #121212;
  color: #C5C5C5;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
}
.market__template {
  display: -ms-grid;
  display: grid;
  padding: 16px 0;
  grid-gap: 2px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
}
.market__template:not(:last-child) {
  border-bottom: 1px solid #242424;
}
.market__template--1-column {
      grid-template-areas: ".";
}
.market__template--2-columns {
      grid-template-areas: ". .";
  -ms-grid-columns: (1fr)[2];
  grid-template-columns: repeat(2, 1fr);
}
.market__template--3-columns,
.market__template--3-columns-no-row-labels,
.market__template--3-columns-no-header-labels {
      grid-template-areas: ". . .";
  -ms-grid-columns: (1fr)[3];
  grid-template-columns: repeat(3, 1fr);
}
.market__template--4-columns {
      grid-template-areas: ". . . .";
  -ms-grid-columns: 3fr (2fr)[3];
  grid-template-columns: 3fr repeat(3, 2fr);
}
.market__template--4-columns-no-header-labels {
      grid-template-areas: ". . . .";
  -ms-grid-columns: (1fr)[4];
  grid-template-columns: repeat(4, 1fr);
}
.market__template--row-label-long {
  -ms-grid-columns: 2fr 1fr;
      grid-template-columns: 2fr 1fr;
}
.market__template--grid-fixed-location {
  grid-auto-flow: column;
}
.market__template--3-columns .market__label:first-child:not(.market__label--row) {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / span 1;
}
.market__template--3-columns-no-row-labels .market__label:first-child {
  grid-column: span 1;
}
.market__template--4-columns .market__label:first-child:not(.market__label--row) {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / span 1;
}
.market__template--truncate-strings .market__label--row,
.market__template--truncate-strings .market__button-yourbet-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.market__template--truncate-strings .market__label--text-center .market__label-inner {
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
}
.market-cells-separator {
  margin: 16px 0;
  height: 1px;
  -ms-grid-column: 1;
      grid-column-start: 1;
  -ms-grid-column-span: 4;
  grid-column-end: 5;
  background-color: #242424;
}
.market-cells-separator:first-of-type {
  margin-top: 0;
}
@media screen and (max-width: 320px) {
  .market__template--4-columns {
        grid-template-areas: ". . . .";
    -ms-grid-columns: (2fr)[4];
    grid-template-columns: repeat(4, 2fr);
  }
}
.odds-update-arrow {
  position: absolute;
  top: 2px;
  right: 2px;
}
.odds-update-arrow--up {
  animation: flash-outcome-cell-arrow 750ms linear both;
  width: 10px;
  height: 10px;
}
.odds-update-arrow--up:before,
.odds-update-arrow--up:after {
  position: absolute;
  content: '';
  background-color: #53D337;
  top: 4px;
  height: 2px;
  width: 6.98267946px;
}
.odds-update-arrow--up:before {
  left: -0.31548295px;
  transform: rotate(-45deg);
}
.odds-update-arrow--up:after {
  left: 3.27030349px;
  transform: rotate(45deg);
}
.odds-update-arrow--down {
  animation: flash-outcome-cell-arrow 750ms linear both;
  width: 10px;
  height: 10px;
}
.odds-update-arrow--down:before,
.odds-update-arrow--down:after {
  position: absolute;
  content: '';
  background-color: #53D337;
  bottom: 4px;
  height: 2px;
  width: 6.98267946px;
}
.odds-update-arrow--down:before {
  left: -0.31548295px;
  transform: rotate(45deg);
}
.odds-update-arrow--down:after {
  left: 3.27030349px;
  transform: rotate(-45deg);
}
@keyframes flash-outcome-cell-arrow {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.side-arrow {
  position: absolute;
  top: 50%;
  padding: 3px 0;
  width: 53px;
  height: 32px;
  border: 0;
  cursor: pointer;
  transform: translateY(-50%);
  z-index: 1;
}
.side-arrow--left {
  background: linear-gradient(to left, rgba(18, 18, 18, 0), #121212);
  text-align: left;
  left: 0;
}
.side-arrow--right {
  background: linear-gradient(to right, rgba(18, 18, 18, 0), #121212);
  text-align: right;
  right: 0;
}
.side-arrow:hover .side-arrow-icon {
  opacity: 1;
}
.side-arrow-icon {
  opacity: 0.6;
}
.markets-subcategories-wrapper {
  position: relative;
  padding: 16px 0;
  background-color: #121212;
}
.markets-subcategories-inner {
  display: flex;
  overflow: hidden;
}
.markets-subcategories {
  box-sizing: border-box;
  position: relative;
  display: flex;
  gap: 8px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.markets-subcategory {
  box-sizing: border-box;
  padding: 7px 16px;
  border: 1px solid #242424;
  border-radius: 50px;
  color: #ababab;
  background-color: #121212;
  white-space: nowrap;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 300ms ease;
}
.markets-subcategory[aria-selected="true"] {
  border-color: #fff;
  color: #fff;
}
.markets-subcategory:hover {
  color: #fff;
}
button.markets-subcategory {
  font-family: 'Saira Condensed', sans-serif;
}
